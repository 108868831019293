import React from "react";

const MEDIUM = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#4B6B80' />
				<path
					d='M7 7V29H29V7H7ZM25.2765 12.2122L24.0968 13.3433C24.0467 13.3814 24.008 13.4325 23.9849 13.491C23.9618 13.5495 23.9552 13.6132 23.9657 13.6752V21.9866C23.9553 22.0484 23.9621 22.112 23.9852 22.1703C24.0083 22.2286 24.0469 22.2795 24.0968 22.3175L25.249 23.4487V23.6971H19.4538V23.4496L20.6473 22.29C20.7647 22.1727 20.7647 22.1388 20.7647 21.96V15.2408L17.4463 23.6696H16.9972L13.1325 15.2408V20.8903C13.1166 21.0074 13.1276 21.1266 13.1646 21.2389C13.2016 21.3512 13.2636 21.4536 13.3461 21.5383L14.8989 23.4212V23.6696H10.4971V23.4221L12.0508 21.5383C12.1326 21.4535 12.1933 21.3507 12.228 21.2381C12.2627 21.1255 12.2705 21.0064 12.2507 20.8903V14.3581C12.2598 14.2686 12.2477 14.1782 12.2154 14.0943C12.1831 14.0103 12.1314 13.9352 12.0646 13.875L10.6841 12.2122V11.9647H14.9686L18.2805 19.2274L21.1918 11.9637H25.2765V12.2122Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default MEDIUM;
