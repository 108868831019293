import React from "react";

const CHROME = props => {
	return (
		<div>
			<svg
				width='36'
				height='37'
				viewBox='0 0 36 37'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18.8378' r='18' fill='#5E88A4' />
				<path
					d='M21.8628 15.8037L28.0183 14.2646C28.6653 15.6933 29 17.2437 29 18.8122C29 24.8603 24.0216 29.8378 17.9707 29.8378C17.6938 29.8378 17.4161 29.8277 17.1392 29.8067L21.6355 22.1552C21.9618 21.8106 22.2359 21.4192 22.4431 20.9902C22.7652 20.3087 22.929 19.5632 22.9222 18.8095C22.9153 18.0558 22.7381 17.3133 22.4037 16.6378C22.2532 16.3415 22.0719 16.0619 21.8628 15.8037ZM18.8928 23.6641L16.9486 29.7892C11.3432 29.2374 7 24.4662 7 18.8167C7 16.6305 7.64992 14.4928 8.86817 12.6769L13.2993 20.3558C13.4643 20.8499 14.3251 22.7217 16.1108 23.4056C17.0256 23.7557 17.9817 23.8465 18.8928 23.6641ZM17.9753 14.8228C16.1364 14.8402 14.4204 16.2382 14.051 18.0422C13.9219 18.704 13.9578 19.3874 14.1556 20.032C14.3534 20.6766 14.7069 21.2626 15.1849 21.7382C16.2978 22.8253 18.0633 23.1847 19.5244 22.5879C20.8151 22.0599 21.7886 20.8215 21.9912 19.4428C22.2405 17.7415 21.2688 15.9302 19.7041 15.1996C19.1627 14.948 18.5723 14.8193 17.9753 14.8228ZM13.4588 16.9697L9.07075 12.3872C10.0944 10.9787 11.4368 9.83223 12.9882 9.04156C14.5396 8.25089 16.256 7.83841 17.9972 7.83783C22.1772 7.83783 26.0126 10.2138 27.8734 13.9566H18.5115C16.8514 13.842 15.4975 14.4332 14.6019 15.2885C14.1075 15.7628 13.7181 16.3355 13.4588 16.9697Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default CHROME;
