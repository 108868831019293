import React from "react";

const JS = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#5E88A4' />
				<path
					d='M7 7H29V29H7V7ZM27.1978 23.753C27.0374 22.7493 26.3838 21.9059 24.4451 21.1194C23.7704 20.8032 23.0206 20.5832 22.7978 20.0744C22.7144 19.7719 22.7016 19.6069 22.7557 19.4282C22.8932 18.836 23.5944 18.6582 24.1444 18.8232C24.5019 18.9332 24.8319 19.2082 25.0391 19.6482C25.9869 19.0285 25.9869 19.0285 26.6478 18.6169C26.4003 18.2319 26.2775 18.066 26.1107 17.9019C25.5332 17.2557 24.7641 16.9257 23.5128 16.9541L22.8666 17.0357C22.2469 17.1869 21.6566 17.5169 21.2991 17.9569C20.2541 19.1403 20.5557 21.2028 21.8207 22.0553C23.0719 22.9903 24.9016 23.1957 25.1353 24.0766C25.3553 25.1491 24.3378 25.4928 23.3332 25.3691C22.5898 25.2041 22.1782 24.8319 21.7244 24.1444L20.0469 25.1078C20.2394 25.5478 20.4594 25.7394 20.7894 26.1244C22.3844 27.7341 26.3719 27.6516 27.0878 25.2041C27.1144 25.1216 27.3078 24.5578 27.1557 23.6916L27.1978 23.753ZM18.9634 17.1117H16.9028C16.9028 18.8883 16.8945 20.6538 16.8945 22.433C16.8945 23.5623 16.9523 24.5991 16.768 24.9181C16.4655 25.5497 15.6863 25.469 15.3325 25.3581C14.9695 25.1784 14.7853 24.9309 14.5717 24.5743C14.5139 24.4781 14.4708 24.3947 14.4553 24.3947L12.7823 25.4259C13.0619 26.0034 13.4698 26.5002 13.996 26.8165C14.7798 27.284 15.833 27.4353 16.9358 27.1877C17.6535 26.9806 18.2723 26.5543 18.5958 25.8943C19.0633 25.0418 18.9643 23.9968 18.9598 22.8272C18.9707 20.9443 18.9598 19.0606 18.9598 17.1631L18.9634 17.1117Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default JS;
