import React from "react";

const LOGO = props => {
	return (
		<div>
			<svg
				width='230'
				height='74'
				viewBox='0 0 230 74'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M103.345 65.4739C103.345 64.2721 102.919 63.353 102.067 62.7167C101.215 62.0663 99.6824 61.3876 97.468 60.6807C95.2536 59.9595 93.5006 59.2526 92.2088 58.5597C88.6885 56.6651 86.9284 54.1129 86.9284 50.9032C86.9284 49.2348 87.3968 47.7502 88.3337 46.4493C89.2847 45.1344 90.6403 44.1092 92.4005 43.374C94.1748 42.6387 96.1621 42.2711 98.3623 42.2711C100.577 42.2711 102.55 42.6741 104.282 43.48C106.013 44.2719 107.355 45.3959 108.306 46.8523C109.271 48.3087 109.754 49.963 109.754 51.8152H103.366C103.366 50.4013 102.919 49.3055 102.025 48.5278C101.13 47.736 99.874 47.3401 98.2558 47.3401C96.6944 47.3401 95.4807 47.6724 94.6149 48.3369C93.749 48.9874 93.316 49.8499 93.316 50.9245C93.316 51.9284 93.8199 52.7696 94.8278 53.4483C95.8498 54.127 97.3474 54.7633 99.3204 55.3572C102.954 56.4459 105.602 57.7962 107.262 59.4081C108.923 61.02 109.754 63.0278 109.754 65.4315C109.754 68.1039 108.739 70.2036 106.709 71.7306C104.679 73.2435 101.946 74 98.5113 74C96.1266 74 93.9548 73.5687 91.9959 72.7062C90.037 71.8296 88.5395 70.6348 87.5033 69.1219C86.4812 67.609 85.9702 65.8557 85.9702 63.862H92.3792C92.3792 67.2696 94.4232 68.9734 98.5113 68.9734C100.03 68.9734 101.215 68.6694 102.067 68.0614C102.919 67.4393 103.345 66.5768 103.345 65.4739Z'
					fill='#5E88A4'
				/>
				<path
					d='M144.741 60.1928H132.477V68.4644H146.87V73.5758H126.089V42.6953H146.828V47.8491H132.477V55.2087H144.741V60.1928Z'
					fill='#5E88A4'
				/>
				<path
					d='M177.251 73.5758H170.863V42.6953H177.251V73.5758Z'
					fill='#5E88A4'
				/>
				<path
					d='M229.639 47.8491H220.143V73.5758H213.755V47.8491H204.386V42.6953H229.639V47.8491Z'
					fill='#5E88A4'
				/>
				<path
					d='M98.2481 19.7215H93.1592V31.0259H86.7716V0.145434H98.2907C101.953 0.145434 104.778 0.958451 106.765 2.58449C108.752 4.21052 109.746 6.50818 109.746 9.47746C109.746 11.5842 109.285 13.3446 108.362 14.7585C107.453 16.1583 106.069 17.2754 104.21 18.1096L110.917 30.729V31.0259H104.061L98.2481 19.7215ZM93.1592 14.5677H98.3119C99.916 14.5677 101.158 14.1647 102.038 13.3587C102.918 12.5386 103.358 11.4146 103.358 9.98648C103.358 8.53012 102.939 7.38482 102.102 6.5506C101.279 5.71637 100.008 5.29926 98.2907 5.29926H93.1592V14.5677Z'
					fill='#5E88A4'
				/>
				<path
					d='M136.212 14.0586L142.664 0.145434H149.647L139.47 19.8275V31.0259H132.976V19.8275L122.798 0.145434H129.803L136.212 14.0586Z'
					fill='#5E88A4'
				/>
				<path
					d='M179.87 24.6632H168.67L166.541 31.0259H159.748L171.289 0.145434H177.208L188.812 31.0259H182.02L179.87 24.6632ZM170.395 19.5094H178.145L174.248 7.9504L170.395 19.5094Z'
					fill='#5E88A4'
				/>
				<path
					d='M230 31.0259H223.612L211.178 10.7076V31.0259H204.79V0.145434H211.178L223.634 20.5062V0.145434H230V31.0259Z'
					fill='#5E88A4'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M27.2989 55.2005V43.4299H29.2535C34.229 43.4299 36.539 45.1114 36.539 49.3594C36.539 53.6074 34.3178 55.2005 29.2535 55.2005H27.2989Z'
					fill='#5E88A4'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0H73.864V73.5758H0V0ZM42.936 49.3594C42.936 54.0499 40.8481 57.0147 37.1165 58.6962L43.3802 68.0773H36.1836L31.2082 60.4662C30.6307 60.5547 30.0087 60.599 29.4312 60.599H27.2989V68.0773H21.0796V37.9871H29.4312C37.8717 37.9871 42.936 42.0139 42.936 49.3594ZM68.491 59.8467C68.491 65.4223 64.093 68.4755 58.1847 68.4755C52.4096 68.4755 48.6781 65.732 46.8567 62.1477L51.921 59.2272C53.2537 61.3955 54.9862 62.9885 58.0514 62.9885C60.628 62.9885 62.2717 61.7052 62.2717 59.9352C62.2717 57.8112 60.5836 57.059 57.7405 55.82L56.1856 55.1562C51.6989 53.2534 48.7225 50.8639 48.7225 45.8194C48.7225 41.1731 52.2764 37.6331 57.8293 37.6331C61.783 37.6331 64.6261 39.0049 66.6696 42.5891L61.8274 45.6867C60.7613 43.7839 59.6063 43.0316 57.8293 43.0316C56.0079 43.0316 54.8529 44.1821 54.8529 45.6867C54.8529 47.5452 56.008 48.2974 58.6734 49.4479L60.2282 50.1117C65.5146 52.3684 68.491 54.6694 68.491 59.8467Z'
					fill='#5E88A4'
				/>
			</svg>
		</div>
	);
};

export default LOGO;
