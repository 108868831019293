import React from "react";

const EMAIL = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#4B6B80' />
				<path
					d='M26.8 9H9.2C7.99 9 7.011 9.95625 7.011 11.125L7 23.875C7 25.0438 7.99 26 9.2 26H26.8C28.01 26 29 25.0438 29 23.875V11.125C29 9.95625 28.01 9 26.8 9ZM26.36 13.5156L18.583 18.2119C18.231 18.4244 17.769 18.4244 17.417 18.2119L9.64 13.5156C9.365 13.3456 9.2 13.0588 9.2 12.7506C9.2 12.0388 10.003 11.6137 10.63 11.9856L18 16.4375L25.37 11.9856C25.997 11.6137 26.8 12.0388 26.8 12.7506C26.8 13.0588 26.635 13.3456 26.36 13.5156Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default EMAIL;
