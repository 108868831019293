import React from "react";

const CHROME = props => {
	return (
		<div>
			<svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M17.74 36.0121V28.3332H19.0102C22.2435 28.3332 23.7446 29.4301 23.7446 32.2015C23.7446 34.9729 22.3012 36.0121 19.0102 36.0121H17.74Z'
					fill='#F6F6F6'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0H48V48H0V0ZM27.9017 32.2015C27.9017 35.2616 26.5448 37.1957 24.1199 38.2927L28.1903 44.4128H23.5137L20.2804 39.4475C19.9051 39.5052 19.501 39.5341 19.1257 39.5341H17.74V44.4128H13.6984V24.7823H19.1257C24.6107 24.7823 27.9017 27.4094 27.9017 32.2015ZM44.5084 39.0433C44.5084 42.6807 41.6504 44.6726 37.8109 44.6726C34.0581 44.6726 31.6331 42.8828 30.4495 40.5445L33.7405 38.6391C34.6066 40.0537 35.7324 41.093 37.7243 41.093C39.3987 41.093 40.4668 40.2558 40.4668 39.101C40.4668 37.7154 39.3698 37.2246 37.5223 36.4163L36.5119 35.9833C33.5962 34.7419 31.662 33.183 31.662 29.892C31.662 26.8609 33.9714 24.5514 37.58 24.5514C40.1493 24.5514 41.9968 25.4463 43.3248 27.7847L40.1781 29.8054C39.4853 28.5641 38.7347 28.0733 37.58 28.0733C36.3964 28.0733 35.6458 28.8239 35.6458 29.8054C35.6458 31.0179 36.3964 31.5087 38.1285 32.2592L39.1389 32.6923C42.5742 34.1646 44.5084 35.6657 44.5084 39.0433Z'
					fill='#F6F6F6'
				/>
			</svg>
		</div>
	);
};

export default CHROME;
