import React from "react";

const VSCODE = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#5E88A4' />
				<path
					d='M28.2208 10.3267L23.6926 8.13633C23.4367 8.01245 23.1488 7.97162 22.8688 8.01949C22.5887 8.06737 22.3305 8.20159 22.1297 8.40356L13.4581 16.3558L9.68153 13.4735C9.51114 13.3435 9.3009 13.2777 9.08726 13.2873C8.87362 13.2969 8.67006 13.3813 8.51188 13.526L7.30006 14.6336C7.20563 14.7199 7.13016 14.8251 7.07849 14.9424C7.02682 15.0597 7.00009 15.1865 7 15.3148C6.99991 15.4431 7.02648 15.57 7.07799 15.6874C7.1295 15.8047 7.20483 15.91 7.29914 15.9964L10.5743 19.0004L7.29914 22.0044C7.20483 22.0908 7.1295 22.1961 7.07799 22.3135C7.02648 22.4308 6.99991 22.5577 7 22.686C7.00009 22.8143 7.02682 22.9412 7.07849 23.0585C7.13016 23.1758 7.20563 23.281 7.30006 23.3672L8.51279 24.4739C8.67098 24.6186 8.87454 24.703 9.08818 24.7126C9.30182 24.7222 9.51206 24.6564 9.68244 24.5264L13.4591 21.6441L22.1306 29.5964C22.3312 29.7984 22.5893 29.9326 22.8692 29.9805C23.1491 30.0284 23.4368 29.9875 23.6926 29.8636L28.2227 27.6733C28.4557 27.5602 28.6523 27.3833 28.7898 27.1629C28.9273 26.9425 29.0002 26.6876 29 26.4274V11.5725C29 11.3121 28.9269 11.0571 28.7891 10.8367C28.6512 10.6163 28.4543 10.4395 28.2208 10.3267ZM23.5037 24.0206L16.924 19.0004L23.5037 13.9803V24.0206Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default VSCODE;
