import React from "react";

const LINKEDIN = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#4B6B80' />
				<path
					d='M25.7431 25.7477H22.4853V20.6428C22.4853 19.4254 22.4605 17.8588 20.7876 17.8588C19.089 17.8588 18.8296 19.1834 18.8296 20.5529V25.7477H15.5717V15.25H18.7013V16.6809H18.7434C19.1807 15.8559 20.244 14.9851 21.8326 14.9851C25.1335 14.9851 25.744 17.1576 25.744 19.9855V25.7477H25.7431ZM11.8923 13.8136C10.8436 13.8136 10.0012 12.9648 10.0012 11.9207C10.0012 10.8775 10.8445 10.0296 11.8923 10.0296C12.9373 10.0296 13.7843 10.8775 13.7843 11.9207C13.7843 12.9648 12.9363 13.8136 11.8923 13.8136ZM13.5258 25.7477H10.2588V15.25H13.5258V25.7477ZM27.3729 7H8.62342C7.726 7 7 7.7095 7 8.58492V27.4151C7 28.2914 7.726 29 8.62342 29H27.3702C28.2667 29 29 28.2914 29 27.4151V8.58492C29 7.7095 28.2667 7 27.3702 7H27.3729Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default LINKEDIN;
