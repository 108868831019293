import React from "react";

const GIT = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#5E88A4' />
				<path
					d='M29.546 16.93L19.067 6.45201C18.463 5.84901 17.485 5.84901 16.879 6.45201L14.708 8.62701L17.468 11.387C18.113 11.172 18.847 11.317 19.357 11.828C19.873 12.343 20.015 13.086 19.795 13.728L22.453 16.388C23.098 16.165 23.84 16.31 24.353 16.823C25.074 17.543 25.074 18.707 24.353 19.427C23.634 20.146 22.472 20.146 21.753 19.427C21.214 18.886 21.079 18.09 21.349 17.431L18.86 14.955V21.48C19.036 21.566 19.202 21.683 19.348 21.828C20.061 22.549 20.061 23.711 19.348 24.428C18.629 25.149 17.459 25.149 16.739 24.428C16.02 23.709 16.02 22.549 16.739 21.83C16.921 21.65 17.126 21.514 17.344 21.424V14.835C17.127 14.744 16.92 14.613 16.744 14.434C16.199 13.889 16.068 13.092 16.348 12.425L13.636 9.70001L6.45 16.881C5.85 17.486 5.85 18.465 6.45 19.07L16.93 29.547C17.534 30.151 18.512 30.151 19.116 29.547L29.546 19.117C30.151 18.514 30.151 17.535 29.546 16.93'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default GIT;
