import React from "react";

const FIREBASE = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#5E88A4' />
				<path
					d='M10.4965 20.714L12.5389 7.40308C12.5536 7.30441 12.5988 7.21298 12.6678 7.1418C12.7369 7.07063 12.8263 7.02334 12.9234 7.00667C13.0205 6.98999 13.1203 7.00478 13.2087 7.04894C13.297 7.09309 13.3693 7.16435 13.4154 7.25257L15.6114 11.4273L10.4965 20.714ZM25 23.9437L23.0553 11.6942C23.041 11.6083 23.0037 11.5281 22.9474 11.4623C22.8912 11.3964 22.8182 11.3475 22.7364 11.3209C22.6545 11.2943 22.5671 11.2909 22.4835 11.3112C22.4 11.3315 22.3235 11.3747 22.2625 11.4361L10 23.9437L16.785 27.8183C16.9942 27.9374 17.2302 28 17.4702 28C17.7102 28 17.9462 27.9374 18.1554 27.8183L25 23.9437ZM19.4862 13.2544L17.9145 10.2075C17.8749 10.1309 17.8153 10.0667 17.7422 10.022C17.6691 9.9772 17.5854 9.95354 17.5 9.95354C17.4146 9.95354 17.3309 9.9772 17.2578 10.022C17.1847 10.0667 17.1251 10.1309 17.0855 10.2075L10.1857 22.7362L19.4862 13.2544Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default FIREBASE;
