import React from "react";

const RUBY = props => {
	return (
		<div>
			<svg
				width='36'
				height='36'
				viewBox='0 0 36 36'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='18' cy='18' r='18' fill='#5E88A4' />
				<path
					d='M24.6365 7.0088C27.2904 7.47101 28.0429 9.29606 27.9869 11.2083L28 11.181L26.8056 26.9294L11.2787 28H11.2928C10.0039 27.9454 7.13125 27.8266 7 23.7856L8.43937 21.1444L10.906 26.9426L11.3461 27.9745L13.8005 19.9242L13.7743 19.9303L13.7882 19.9039L21.8864 22.5064L20.6649 17.7249L19.7986 14.2914L27.5161 13.7905L26.978 13.3415L21.4375 8.79688L24.6391 7L24.6365 7.0088ZM7 23.7415V23.7644V23.7389V23.7415ZM11.4887 11.402C14.6046 8.29154 18.6261 6.45328 20.1705 8.02126C21.7122 9.58571 20.0786 13.3961 16.9566 16.5047C13.839 19.6143 9.8665 21.5529 8.32562 19.9884C6.78037 18.424 8.365 14.515 11.4861 11.4046L11.4887 11.402Z'
					fill='white'
				/>
			</svg>
		</div>
	);
};

export default RUBY;
